import { RESPONSE } from './../../shared/utils/constants';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { VendorService } from 'src/app/modules/vendor/shared/vendor.service';
import {
  GetVendors,
  AddVendors,
  UpdateVendors,
  DeleteVendors,
  SelectedVendor,
} from './vendor.actions';
import {
  LOAVendorDetail,
  Vendor,
  VendorDetail,
} from './vendor.model';

export class VendorStateModel {
  vendors: VendorDetail[];
  loaVendor: LOAVendorDetail[];
  count: number;
  selectedVendor;
}

@State<VendorStateModel>({
  name: 'Vendor',
  defaults: {
    vendors: null,
    loaVendor: null,
    selectedVendor: null,
    count: 0,
  },
})
// export class LOAVendorStateModel {
//   loaVendor: LOAVendorDetail[];
//   count: number;
// }
// @State<LOAVendorStateModel>({
//   name: 'LOAVendor',
//   defaults: {
//     loaVendor: null,
//     count: 0,
//   },
// })
@Injectable({
  providedIn: 'root',
})
export class VendorState {
  constructor(private service: VendorService) {}

  @Selector()
  static getVendorList(state: VendorStateModel) {
    return state.vendors;
  }

  @Selector()
  static getSelectedVendor(state: VendorStateModel) {
    return state.selectedVendor;
  }
  @Selector()
  static getCount(state: VendorStateModel): number {
    return state.count;
  }

  @Action(GetVendors)
  getVendors(
    { getState, setState }: StateContext<VendorStateModel>,
    { search, page }: GetVendors,
  ) {
    let params = { ...search };
    params = { ...params, page: page.toString() || '1' };
    return this.service.getVendorList(params).pipe(
      tap((result: RESPONSE<VendorDetail>) => {
        const state = getState();
        setState({
          ...state,
          vendors: result.results,
          count: result.count,
        });
      }),
    );
  }

  @Action(AddVendors)
  addVendor(
    { getState, patchState }: StateContext<VendorStateModel>,
    { payload }: AddVendors,
  ) {
    return this.service.createVendor(payload).pipe(
      tap((result) => {
        const state = getState();
      }),
    );
  }

  @Action(UpdateVendors)
  updateVendor(
    { getState, setState }: StateContext<VendorStateModel>,
    { payload, id }: UpdateVendors,
  ) {
    return this.service.updateVendor(id, payload).pipe(
      tap((result) => {
        const state = getState();
        const userList = [...state.vendors];
        const userIndex = userList.find((item) => item.id === id);
        setState({
          ...state,
        });
      }),
    );
  }

  @Action(DeleteVendors)
  deleteVendor(
    { getState, setState }: StateContext<VendorStateModel>,
    { id }: DeleteVendors,
  ) {
    return this.service.deleteVendor(id).pipe(
      tap(() => {
        const state = getState();
        state.vendors = state.vendors.filter(
          (item) => item.id !== id,
        );
        setState({
          ...state,
        });
      }),
    );
  }

  @Action(SelectedVendor)
  setSelectedVendorId(
    { getState, setState }: StateContext<VendorStateModel>,
    { id }: SelectedVendor,
  ) {
    return this.service.getVendor(id).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          selectedVendor: res,
        });
      }),
    );
  }
}

import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ThemeService } from '../service/theme.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appSetTheme]',
})
export class SetThemeDirective implements OnInit, OnDestroy {
  themeList: any;

  @Input() type: string;
  themeSubscription: Subscription[] = [];

  constructor(
    private themeService: ThemeService,
    private element: ElementRef,
    private renderer: Renderer2,
  ) {
    this.themeSubscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
        if (this.themeList) {
          switch (this.type) {
            case 'headerTable':
              this.setThemeInWeb(
                'header_table_color',
                'text_header_table_color',
              );
              break;
            case 'button':
              this.setThemeInWeb(
                'header_table_color',
                'text_header_table_color',
              );
              break;
            case 'submit':
              this.setThemeInWeb(
                'header_table_color',
                'text_header_table_color',
              );
              break;
            case 'headerContainer':
              this.setThemeInWeb(
                'hovered_text_color',
                'hovered_text_color',
                true,
              );
          }
        }
      }),
    );
  }

  ngOnInit() {
    this.themeService.themeSetting();
  }

  ngOnDestroy(): void {
    this.themeSubscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (_) {}
    });
  }

  setThemeInWeb(bg: string, text: string, creation = false) {
    this.renderer.setStyle(
      this.element.nativeElement,
      'background',
      creation
        ? this.themeService.themeList[bg] + 20
        : this.themeService.themeList[bg],
    );
    this.renderer.setStyle(
      this.element.nativeElement,
      'color',
      this.themeService.themeList[text],
    );
  }
}

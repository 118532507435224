import {
  Directive,
  Input,
  Renderer2,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: '[appWordingRequired]',
})
export class WordingRequiredDirective {
  @Input() set condition(val: boolean) {
    if (val) {
      if (!this.errorMessageElement) {
        this.errorMessageElement = this.renderer.createElement('div');
        const text = this.renderer.createText(
          this.wording || 'กรุณากรอกข้อมูล',
        );
        this.renderer.addClass(this.errorMessageElement, 'mt-1');
        this.renderer.addClass(this.errorMessageElement, 'text-red');
        this.renderer.appendChild(this.errorMessageElement, text);
        // add style invalid class
        this.renderer.appendChild(
          this.el.nativeElement.parentNode,
          this.errorMessageElement,
        );
      }
      this.renderer.addClass(this.el.nativeElement, 'is-invalid');
    } else {
      if (this.errorMessageElement) {
        this.renderer.removeChild(
          this.el.nativeElement.parentNode,
          this.errorMessageElement,
        );
        this.errorMessageElement = null;
      }
      this.renderer.removeClass(this.el.nativeElement, 'is-invalid');
    }
  }

  @Input() wording: string;

  private errorMessageElement?: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}
}

import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDefaultAmountZero]',
})
export class DefaultAmountZeroDirective implements OnInit, OnDestroy {
  @Input() minimumNumber = 0;
  @Input() maximumNumber: number;
  private subscription: Subscription;

  constructor(private ngControl: NgControl) {}

  ngOnInit() {
    const control = this.ngControl.control;
    this.subscription = control.valueChanges.subscribe((_val) => {
      if (_val === null || _val === undefined || _val === '') {
        control.setValue(this.minimumNumber);
      }
      if (this.maximumNumber && _val > this.maximumNumber) {
        control.setValue(this.maximumNumber);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

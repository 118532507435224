<div class="row">
  <div class="col">
    <div class="center form-group">
      <ngx-file-drop class="file-drop pointer" dropZoneLabel="Drop files here" (click)="multipleFileUpload.click()"
        (onFileDrop)="dropped($event)">
        <ng-template ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector">
      <svg-icon src="assets/svg/upload.svg"
          [svgStyle]="{ 'fill': '#2D5B8F', 'height.px': 20, 'width.px': 20}">
      </svg-icon>
      <span class="cus-blue pl-2">
        {{"UPLOAD.Browse or Drop files here"|translate}}
      </span>
    </ng-template>
      </ngx-file-drop>
      <input type="file"
          hidden
          multiple
          accept="audio/*,video/*,image/*,.csv,.ppt,.pptx,.pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
          application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          #multipleFileUpload
          [(ngModel)]="fileUpload"
          (change)="selectFile($event)">
    </div>
    <div class="upload-table"
        style="word-break: break-all;"
        *ngIf="memoData && memoData[inputComponent.controlName]
         && memoData[inputComponent.controlName]?.length !== 0">
<!--      <div class="text-right"-->
<!--          style="height: 2rem">-->
<!--        <a *ngIf="files?.length > 1 && hasSomeFileId()"-->
<!--            class="pb-1 text-danger"-->
<!--            role="button"-->
<!--            href="javascript:void(0)"-->
<!--            [popoverTitle]="clearAllPopTitle"-->
<!--            [ngbPopover]="clearAllPopContent"-->
<!--            placement="left">-->
<!--          <span>{{ 'MEMOS.CLEAR-ALL' | translate }}</span>-->
<!--        </a>-->
<!--        <a *ngIf="files?.length > 1 && !hasSomeFileId()"-->
<!--            class="pb-1 text-danger"-->
<!--            role="button"-->
<!--            href="javascript:void(0)"-->
<!--            (click)="removeAllFiles()">-->
<!--          <span>{{ 'MEMOS.CLEAR-ALL' | translate }}</span>-->
<!--        </a>-->
<!--      </div>-->

      <table class="table">
        <tbody class="upload-name-style">
          <tr *ngFor="let item of files; let i = index">
            <td><strong>{{ item.id ? item.file_name : item.name }}</strong></td>
            <td width="5%"
                *ngIf="item.id" class="text-right">
              <ng-template #popTitle>
                <small><strong>{{ item.file_name }}</strong> {{ 'CREATE.DEL-QUESTION' | translate }}</small>
                <small>{{ 'CREATE.SURE-QUESTION' | translate }}</small>
              </ng-template>
              <ng-template #popContent>
                <div class="d-inline-flex justify-content-center w-100p">
                  <button class="btn btn-danger mx-1"
                      style="line-height: 1rem"
                      id="confirm-remove"
                      (click)="removeFile(i, item)">
                    <small>{{ 'MEMOS.DELETE' | translate }}</small>
                  </button>
                  <button class="btn btn-light mx-1"
                      style="line-height: 1rem">
                    <small>{{ 'MEMOS.CANCEL' | translate }}</small>
                  </button>
                </div>
              </ng-template>
              <a href="javascript:void(0)"
                  [ngbPopover]="popContent"
                  [popoverTitle]="popTitle"
                  placement="left">
                <i class="fas fa-times text-red"></i>
              </a>
            </td>
            <td *ngIf="!item.id" class="text-right">
              <a href="javascript:void(0)"
                  (click)="removeFile(i, item)">
                <i class="fas fa-times text-red"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
   <ng-content select="#note"></ng-content>
  </div>
</div>
<ng-template #clearAllPopTitle>
  <small>{{ 'CREATE.SURE-QUESTION-ALL' | translate }}</small>
</ng-template>
<ng-template #clearAllPopContent>
  <div class="d-inline-flex justify-content-center w-100p">
    <button class="btn btn-danger mx-1"
        style="line-height: 1rem"
        id="confirm-remove"
        (click)="removeAllFiles()">
      <small>{{ 'MEMOS.DELETE' | translate }}</small>
    </button>
    <button class="btn btn-light mx-1"
        style="line-height: 1rem">
      <small>{{ 'MEMOS.CANCEL' | translate }}</small>
    </button>
  </div>
</ng-template>

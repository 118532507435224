import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { DropdownItem } from '../../../../../shared/models/common.model';
import { User } from '../../../../../shared/models/user.models';
import { DropdownService } from '../../../../../shared/service/dropdown.service';
import { CertificateStatus } from '../../../../certificate/shared/certificate.model';
import { Member } from '../../../../loa/shared/loa.model';
import { ApproverDropdown } from '../../../model/memo.model';
import { DdocLawSectionObj } from '../../upload-memos/loa-customizer/signing-law-section-radio/signing-law-section-radio.component';
import { MemoService } from '../../../service/memo.service';
@Component({
  selector: 'app-approval-dropdown',
  templateUrl: './approval-dropdown.component.html',
  styleUrls: ['./approval-dropdown.component.scss'],
})
export class ApprovalDropdownComponent implements OnChanges, OnInit {
  certificateStatus: CertificateStatus = null;
  people$: Observable<ApproverDropdown[]>;
  peopleInput$ = new Subject<string>();
  peopleLoading = false;
  initialPeople$: Observable<ApproverDropdown[]>;

  @Input() clearable = false;
  @Input() selectedApprovals: number[] = [];
  @Input() approval: Partial<Member>;
  @Input() disabled = false;
  @Input() disabledApprover = false;
  @Input() freeLawSelection = false;
  @Input() enableAttachSignature = false;
  @Input() ddocEnable = false;
  @Input() ddocLawSection: DdocLawSectionObj;
  @Input() allowLOAChange = true;
  @Input() jobPositions: string[] = [];
  @Input() vendor: number = null;
  _groups: string[] = ['General'];
  @Input() set groups(val) {
    this._groups = val;
  }
  @Output() approvalChange = new EventEmitter();
  @Output() ddocLawSectionChange =
    new EventEmitter<DdocLawSectionObj>();

  constructor(
    private dropdownService: DropdownService,
    private memoService: MemoService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ddocEnable) {
      if (!changes.ddocEnable.currentValue) {
        this.ddocLawSection = { lawSection: null };
      }
    }
  }

  ngOnInit(): void {
    if (this.jobPositions?.length > 0) {
      const dropdownParams = {
        type: 'people',
        all: 'true',
        group: this._groups.join(','),
        with_signature: true,
        job_positions: this.jobPositions.join(','),
      };
      this.initialPeople$ = this.getPeople(dropdownParams);
    } else {
      this.initialPeople$ = of([] as ApproverDropdown[]);
    }
    this.setPeople();
    this.memoService.clearPeopleDropdown$.subscribe((clearPeople) => {
      if (clearPeople) {
        this.setPeople();
        this.memoService.updateClearPeopleDropdown(false);
      }
    });
  }

  setPeople(): void {
    this.people$ = concat(
      this.initialPeople$,
      this.peopleInput$.pipe(
        distinctUntilChanged(),
        filter((term) => term != null && term !== ''),
        debounceTime(300),
        tap(() => (this.peopleLoading = true)),
        switchMap((query) => {
          let params: { [k: string]: any } = {
            type: 'people',
            all: 'true',
            group: this._groups.join(','),
            with_signature: true,
            query,
          };
          if (this.jobPositions) {
            params = {
              ...params,
              job_positions: this.jobPositions.join(','),
            };
          }
          if (this.vendor) {
            params = {
              ...params,
              department: this.vendor,
            };
          }
          if (this.enableAttachSignature) {
            params.with_signature = true;
          }
          return this.getPeople(params);
        }),
        catchError(() => of([] as ApproverDropdown[])),
        tap(() => (this.peopleLoading = false)),
      ),
    );
  }

  getPeople(params) {
    return this.dropdownService.getDropdown(params).pipe(
      map((obj) =>
        obj.people.map(
          (people: DropdownItem<number, PeopleDropdownContext>) => {
            const approver: ApproverDropdown = {
              person: people.value,
              full_name: people.label,
            };
            if (this.enableAttachSignature) {
              approver.signature = people.context.signature;
            }
            return approver;
          },
        ),
      ),
      map((people) => {
        return people.filter(
          (person) => !this.selectedApprovals.includes(person.person),
        );
      }),
    );
  }

  onDropdownChange(dropdown: ApproverDropdown): void {
    this.approvalChange.emit(dropdown);
  }
}

interface PeopleDropdownContext extends Partial<User> {
  signature: string;
}

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { API_URL } from '../../../shared/service/api.constant';
import {
  CommentList,
  ComponentData,
  ComponentSessionConfig,
  MemoDetail,
  MemoListDetail,
  ReimbursementParameter,
} from '../model/memo.model';
import { UploadMemoPayload } from '../model/template.model';

@Injectable({
  providedIn: 'root',
})
export class MemoService {
  headers: HttpHeaders;
  historyText: string;
  loaList: any;
  _reimbursementItems: ReimbursementParameter;
  private setLoa = new Subject();
  data = this.setLoa.asObservable();

  changeTable: any;
  private setTable = new Subject();
  tableData = this.setTable.asObservable();
  requestPo: Observable<any>;

  private setFormat = new Subject();
  inputDateFormat = this.setFormat.asObservable();

  private departmentSubject = new Subject();
  department$ = this.departmentSubject.asObservable();

  private powerBuyTypeOfRequestSubject = new Subject();
  powerBuyTypeOfRequest$ =
    this.powerBuyTypeOfRequestSubject.asObservable();

  private vendorTypeOfRequestSubject = new Subject();
  vendorTypeOfRequest$ =
    this.vendorTypeOfRequestSubject.asObservable();

  private clearPeopleDropdownSubject = new Subject();
  clearPeopleDropdown$ =
    this.clearPeopleDropdownSubject.asObservable();

  private reimbursementLOASubject = new Subject();
  reimbursementLOA$ = this.reimbursementLOASubject.asObservable();

  private financialTypeSubject = new Subject();
  financialType$ = this.financialTypeSubject.asObservable();

  isSave$ = new BehaviorSubject<boolean>(false);
  summitted$ = this.isSave$.asObservable();

  setLoadingDetails$ = new BehaviorSubject<boolean>(false);
  loadingDetails$ = this.setLoadingDetails$.asObservable();

  private setWidthForDate = new Subject();
  widthForDate = this.setWidthForDate.asObservable();

  setBranch$ = new Subject<number>();
  loadBranch$ = this.setBranch$.asObservable();

  constructor(
    private http: ApiService,
    private httpClient: HttpClient,
  ) {}

  setReimbursementItem(item: ReimbursementParameter) {
    this._reimbursementItems = item;
  }

  getReimbursementItems(): ReimbursementParameter {
    return this._reimbursementItems;
  }

  getDepartmentList(data?: { [type: string]: string }) {
    return this.http.get(API_URL.departments, data);
  }

  previewMemoPDF(data: { [type: string]: string }) {
    return this.http.pdfPost(API_URL.memo_preview, data);
  }

  // My task
  getMyTaskList(params?: { [type: string]: string }) {
    return this.http.get(API_URL.my_task, params);
  }

  getMyTaskBadge(params?: { [type: string]: string }) {
    return this.http.get(API_URL.my_task + 'badge/', params);
  }

  /**
   * Get the unique sessions property from `ComponentData`.
   * If all components is undefined a session then it return empty array.
   */
  getInputComponentSessions(
    inputComponents: ComponentData[],
    ignoreUndefinedGroup = true,
  ): ComponentSessionConfig[] {
    const sessionNames: string[] = [];
    const sessions: ComponentSessionConfig[] = [];
    const retrieveSessionName = (
      session: string | ComponentSessionConfig,
    ) => {
      if (typeof session === 'object') {
        return session.sessionName;
      }
      return session;
    };
    inputComponents.forEach((component) => {
      const sessionName = retrieveSessionName(component.session);
      if (ignoreUndefinedGroup && !sessionName) {
        return;
      }
      const isDuplicated = sessionNames.includes(sessionName);
      if (!isDuplicated && component.componentName != null) {
        sessionNames.push(sessionName);
        sessions.push(this.rewrapSessionConfig(component.session));
      }
    });
    return sessions;
  }

  // RealMemo
  getGeneralMemoList(params?: {
    [type: string]: string;
  }): Observable<MemoListDetail> {
    return this.http.get<MemoListDetail>(
      API_URL.general_memo,
      params,
    );
  }

  getMemoListCSV(data) {
    // url นี้จะไม่ return file อีกแล้ว แต่จะ return celery task id มาแทน
    return this.http.get(API_URL.general_memo + 'excel/', data);
  }

  getMemoTypeRefundCSV(data) {
    return this.http.get(API_URL.general_memo + 'pwb-excel/', data);
  }

  getMemoFiles(data) {
    return this.http.getBlob(
      API_URL.general_memo + 'download-multiple-pdf/',
      data,
    );
  }

  getMemoReferenceById(id) {
    return this.http.get(API_URL.memos + id + '/references/');
  }

  updateMemoReferenceById(id, data) {
    return this.http.post(
      API_URL.memos + id + '/update-references/',
      data,
    );
  }

  // Action
  createMemo(data: UploadMemoPayload): Observable<MemoDetail> {
    return this.http.post(API_URL.memos, data);
  }

  updateMemo(id, data): Observable<MemoDetail> {
    return this.http.patch(API_URL.memos + id + '/', data);
  }

  updateAnnouncement(id, data, header?) {
    return this.http.post(
      API_URL.memos + id + '/announcement/',
      data,
      header,
    );
  }

  publishMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/publish/', data);
  }

  extendMemo(id, data) {
    return this.http.post(API_URL.memos + id + '/extend/', data);
  }

  reviseMemo(id) {
    return this.http.post(API_URL.memos + id + '/revise/', {});
  }

  recallMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/recall/', data);
  }

  approveMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/approve/', data);
  }

  rejectMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/reject/', data);
  }

  terminateMemo(id, data?) {
    return this.http.post(API_URL.memos + id + '/terminate/', data);
  }

  downloadMemo(id, data?) {
    return this.http.postResponseBlob(
      API_URL.memos + id + '/download-pdf/',
      data,
    );
  }

  getMemoDetail(id: number): Observable<MemoDetail> {
    return this.http.get(API_URL.memos + id + '/');
  }

  getMemoHistory(params?: { [type: string]: string }) {
    return this.http.get(API_URL.memos_history, params);
  }

  getHistoryLogCSV(data) {
    return this.http.getBlob(API_URL.memos_history + 'excel/', data);
  }

  deleteMemo(id) {
    return this.http.delete(API_URL.memos + id + '/');
  }

  // Upload Blob

  uploadBlob(id, data) {
    return this.http.patch(API_URL.upload_memo_blob + id + '/', data);
  }

  uploadMultiBlob(id, data) {
    return this.http.patch(
      API_URL.upload_memo_blob_multi + id + '/',
      data,
    );
  }

  removeBlob(data) {
    return this.http.post(API_URL.remove_memo_blob, data);
  }

  // Comment

  getCommentList(params): Observable<CommentList[]> {
    return this.http.get(API_URL.memo_comment, params);
  }

  createNewComment(data): Observable<CommentList> {
    return this.http.post(API_URL.memo_comment, data);
  }

  updateMemoRead(data) {
    return this.http.post(API_URL.memo_read, data);
  }

  deleteComment(id) {
    return this.http.delete(API_URL.memo_comment + id + '/');
  }

  // Attachment
  getMemoAttachment(params?: { [type: string]: string }) {
    return this.http.get(API_URL.memo_attachment, params);
  }

  removeMemoAttachment(id) {
    return this.http.delete(API_URL.memo_attachment + id + '/');
  }

  updateMemoAttchment(id, data) {
    return this.http.patch(API_URL.memo_attachment + id + '/', data);
  }

  uploadMemoAttachment(data) {
    return this.http.post(API_URL.memo_bulk_attachment, data);
  }

  downloadMemoAttachment(id, data?) {
    return this.http.post(
      API_URL.memo_attachment + id + '/download/',
      data,
    );
  }

  downloadAttachmentLog(id, data?) {
    return this.http.post(
      API_URL.memos + id + '/download-attachment/',
      data,
    );
  }

  // Verify Duplicated Memo Number
  verifyMemoNumber(params) {
    return this.http.get(API_URL.memo_number_verify, params);
  }

  printFile(url) {
    return this.http.printFile(url);
  }

  getMemoTypes() {
    const params = { type: 'memo_type' };
    return this.http.get(API_URL.dropdown, params);
  }

  getMemoUploadTypes() {
    const params = { type: 'memo_upload_type' };
    return this.http.get(API_URL.dropdown, params);
  }

  addHashtag(id, hashtags: string[]) {
    return this.http.post(API_URL.memos + id + '/add-hashtag/', {
      hashtags,
    });
  }

  setLoadLoaList(department) {
    this.loaList = department;
    this.setLoa.next(this.loaList);
  }

  setInputDate(date) {
    this.setFormat.next(date);
  }

  setWidthDate(event?) {
    this.setWidthForDate.next(event);
  }

  getMemoRevised(params) {
    return this.http.get(API_URL.memo_revised, params);
  }

  // acknowledgement
  getAcknowledge(params) {
    return this.http.get('/api/acknowledges/', params);
  }

  getAcknowledgeCSV(data) {
    return this.http.getBlob('/api/acknowledges/excel/', data);
  }

  // download file
  createDownloadFile(data: any, filename: string): void {
    // for IE10+
    const blob = new Blob([data], { type: data.type });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  rewrapSessionConfig(
    session: string | ComponentSessionConfig,
  ): ComponentSessionConfig {
    if (typeof session === 'string') {
      return { sessionName: session };
    }
    return session;
  }

  updateMemoDetail(id, data) {
    return this.http.patch(API_URL.memo_detail + id + '/', data);
  }

  // trash
  getFinishedMemoList(params?: {
    [type: string]: string;
  }): Observable<MemoListDetail> {
    return this.http.get<MemoListDetail>(
      API_URL.finished_memo,
      params,
    );
  }

  bulkTrashMemo(data) {
    return this.http.post(API_URL.bulk_trash_memo, data);
  }

  bulkUntrashMemo(data) {
    return this.http.post(API_URL.bulk_untrash_memo, data);
  }

  bulkPermanentlyDeleteMemo(data) {
    return this.http.post(API_URL.bulk_permanently_delete_memo, data);
  }

  getFinishedMemoListCSV(data) {
    return this.http.get(API_URL.finished_memo + 'excel/', data);
  }

  exportMemoToGDrive(data) {
    return this.http.post(
      API_URL.finished_memo + 'export-gdrive/',
      data,
    );
  }
  getNdidCredit() {
    return this.http.get('/api/ndid/credit/');
  }

  getThaicomCredit() {
    return this.http.get('/api/thaicom/credit/');
  }

  updateDepartment(newData: number) {
    this.departmentSubject.next(newData);
  }

  updatePowerBuyTypeOfRequest(newData: number) {
    this.powerBuyTypeOfRequestSubject.next(newData);
  }

  updateVendorTypeOfRequest(newData: number) {
    this.vendorTypeOfRequestSubject.next(newData);
  }

  updateReimbursementLOA() {
    this.reimbursementLOASubject.next();
  }

  updateFinancialType(newData: number) {
    this.financialTypeSubject.next(newData);
  }

  updateClearPeopleDropdown(newData: boolean) {
    this.clearPeopleDropdownSubject.next(newData);
  }

  removeAllMemoAttachment(memo_id) {
    return this.http.post('/api/memo-attachments/remove-all/', {
      memo_id,
    });
  }

  prepareServiceUploadOtherAttachments(id, data) {
    return this.http.patch(
      '/api/upload-scb-refund-detail-attachment/' + id + '/',
      data,
    );
  }
  uploadCreditRefundAttachment(id, data) {
    return this.http.patch(
      '/api/upload-credit-refund-attachment/' + id + '/',
      data,
    );
  }

  removeScbRefundsAttachment(params) {
    return this.http.deleteWithParams(
      '/api/delete-scb-refund-detail-attachment/',
      params,
    );
  }

  removeCreditRefundAttachment(params) {
    return this.http.deleteWithParams(
      '/api/delete-credit-refund-attachment/',
      params,
    );
  }

  getScbAttachment(params?: { [type: string]: string }) {
    return this.http.get(
      '/api/retrieve-scb-refund-detail-attachment/',
      params,
    );
  }
  getCreditRefundAttachment(params?: { [type: string]: string }) {
    return this.http.get(
      '/api/retrieve-credit-refund-attachment/',
      params,
    );
  }

  setFormSummit(value) {
    return this.isSave$.next(value);
  }
  formSummited() {
    this.isSave$.asObservable();
  }
}

import { VendorPayload } from './vendor.model';

export class GetVendors {
  static readonly type = '[Vendors] Get Vendors';
  constructor(public search, public page: string) {}
}
export class AddVendors {
  static readonly type = '[Vendors] Add Vendors';
  constructor(public payload: VendorPayload) {}
}
export class UpdateVendors {
  static readonly type = '[Vendors] Update Vendors';
  constructor(public id: number, public payload: VendorPayload) {}
}
export class DeleteVendors {
  static readonly type = '[Vendors] Delete Vendors';
  constructor(public id: number) {}
}
export class SelectedVendor {
  static readonly type = '[Vendors] Select Vendors';
  constructor(public id: string) {}
}

<ng-template #content>
  <div class="modal-header">
    <ng-content select="#custom-header"></ng-content>
    <button type="button"
        class="close"
        aria-label="Close"
        (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-4 scroll-popup">
    <ng-content select="#custom-body"></ng-content>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline btn-confirm" (click)="closeModal()"
        [style.--c]="themeList?.header_table_color"
        [style.--bg]="'#fff'">
      <ng-content select="#custom-wording-left"></ng-content>
    </button>
    <button class="btn mx-2 btn-confirm" (click)="confirm()"
            [ladda]="loading"
            appSetTheme type="button">
      <ng-content select="#custom-wording-right"></ng-content>
    </button>
  </div>
</ng-template>

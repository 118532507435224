<ng-template #confirmationModal let-c="close" let-d="dismiss" class="modal-dialog" let-modal>
  <div class="modal-header">
    <h4 class="modal-title flex-grow-1">
      {{contentText()}}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" [class.f-18]="actionType !== 'reject'"
      [ngClass]="actionType ==='approve' ? 'p-5' : actionType ==='reject' ? 'py-3' : 'py-4'">
    <div class="text-center" *ngIf="!enableReason">
      <span>{{"APPROVAL.CONTENT-NO-REQUIRED"|translate}}</span>
    </div>

    <div *ngIf="actionType === 'reject'">
      <div class="row no-gutters custom-scale-radio mb-4">
        <div class="col-12 mb-2 pt-1">
          <label class="form-check-label" required>{{'APPROVAL.SELECT-LOA-TO-REVISE-DOCS'|translate}}</label>
        </div>
        <div class="col-auto form-check form-check-inline pr-3 pointer">
          <input class="form-check-input pointer" type="radio" name="creator-loa" id="creator-loa"
                 [(ngModel)]="rejectTo"  [value]="'creator'">
          <label class="form-check-label pointer" for="creator-loa">
            {{'APPROVAL.CREATED-USER'|translate}}</label>
        </div>
        <div class="col-auto form-check form-check-inline px-3">
          <input class="form-check-input pointer" type="radio" name="previous-loa" id="previous-loa"
                 [(ngModel)]="rejectTo" [value]="'previous_level'">
          <label class="form-check-label pointer"
          for="previous-loa" >{{'APPROVAL.PREVIOUS-APPROVER'|translate}}</label>
        </div>
      </div>

    </div>

    <div *ngIf="enableReason">
      <span>{{ reasonLabel | translate }}</span>
      <div class="form-group mt-2">
        <textarea
          class="form-control"
          fz-elastic
          [(ngModel)]="reason"
          (ngModelChange)="onReasonChange()">
        </textarea>
      </div>
    </div>
 </div>

  <div class="modal-footer">
    <button class="btn btn-outline btn-confirm"
        [style.--c]="themeList?.header_table_color"
        [style.--bg]="'#fff'"
        (click)="close()">
      {{"MEMOS.CANCEL" | translate}}
    </button>
    <button class="btn btn-confirm"
        appSetTheme
        type="button"
        [disabled]="enableReason && !reason"
        (click)="submit()">
      {{"MEMOS.CONFIRM" | translate}}
    </button>
  </div>
</ng-template>

import { Component, Input, OnInit } from '@angular/core';
import { ComponentData } from '../../../modules/memos/model/memo.model';
import { SweetAlertService } from '../../service/sweet-alert.service';
import { MemoService } from '../../../modules/memos/service/memo.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { AlertService } from '../../../core/services/alert.service';
import { SaveItemMemo } from '../../../store/memo/memo.actions';
import * as _ from 'lodash';

@Component({
  selector: 'app-memo-credit-attachments',
  templateUrl: './memo-credit-attachments.component.html',
  styleUrls: ['./memo-credit-attachments.component.scss'],
})
export class MemoCreditAttachmentsComponent implements OnInit {
  file: File;
  fileUpload: File;
  memoData;
  files;
  totalFile = 0;
  loading = false;
  @Input() inputComponent: ComponentData;
  @Input() index: number;
  @Input() controlName: string;

  constructor(
    private swal: SweetAlertService,
    private memoService: MemoService,
    private translate: TranslateService,
    private store: Store,
    private alert: AlertService,
  ) {
    this.store.subscribe((state) => {
      this.memoData = state.memoCreationData;
      this.files = this.memoData?.[this.inputComponent?.controlName];
    });
  }

  ngOnInit() {
    if (this.memoData[this.inputComponent.controlName] == null) {
      this.memoData[this.inputComponent.controlName] = [];
    }
    this.files = this.memoData[this.inputComponent.controlName];
  }

  checkFileSize(file: File): boolean {
    if (file != null && file.size / (1000 * 1000) >= 25) {
      this.alert.error(
        this.translate.instant(
          'MEMOS.Please upload attachment files less than 25 MB',
        ),
      );
      return false;
    }
    return true;
  }

  checkTotalFileSize(file: File) {
    this.totalFile += file.size;
    if (this.totalFile / (1000 * 1000) >= 45) {
      this.alert.error(
        this.translate.instant(
          'MEMOS.The total file size exceeds 45 MB',
        ),
      );
      this.totalFile -= file.size;
      return false;
    }
    return true;
  }

  dropped(event) {
    for (const droppedFile of event) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry =
          droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (!this.checkFileSize(file)) {
            return;
          }
          if (!this.checkTotalFileSize(file)) {
            return;
          }
          this.addFile(file);
        });
      }
    }
    this.memoData = this.store.selectSnapshot(
      (state) => state.memoCreationData,
    );
  }

  selectFile(fileInput) {
    _.map(fileInput.target.files, (file) => {
      if (!this.checkFileSize(file)) {
        return;
      }
      if (!this.checkTotalFileSize(file)) {
        return;
      }
      this.addFile(file);
    });
    this.memoData = this.store.selectSnapshot(
      (state) => state.memoCreationData,
    );
    fileInput.target.value = '';
  }

  removeFile(fileIndex, item) {
    this.files.splice(fileIndex, 1);
    this.memoData[this.inputComponent.controlName] = this.files;
    if (item.id) {
      const controlName = this.inputComponent.controlName;
      const params = {
        attachment_type: controlName.includes('copies')
          ? controlName.replace('copies', 'copy')
          : controlName.replace('attachments', 'attachment'),
        attachment_id: item.id.toString(),
      };
      this.memoService.removeCreditRefundAttachment(params).subscribe(
        () => {
          this.swal.toastNotification({
            type: 'success',
            content: this.translate.instant('MEMOS.SAVE-CHANGES'),
          });
        },
        (error) => {
          const errorMsg = error.error.message;
          this.swal.toastNotification({
            type: 'error',
            content: errorMsg,
          });
        },
      );
      return this.totalFile;
    }
    this.updateMemo();
    this.totalFile -= item.size;
    return this.totalFile;
  }
  //
  // async removeAllFiles() {
  //   if (this.memoData.id && this.hasSomeFileId()) {
  //     this.loading = true;
  //     try {
  //       // await this.memoService
  //       //   .removeAllMemoAttachment(this.memoData.id)
  //       //   .toPromise();
  //       this.swal.toastNotification({
  //         type: 'success',
  //         content: this.translate.instant('MEMOS.SAVE-CHANGES'),
  //       });
  //     } catch (err) {
  //       this.swal.toastNotification({
  //         type: 'error',
  //         content: err.status,
  //       });
  //     }
  //     this.loading = false;
  //   }
  //   this.files = [];
  //   this.memoData[this.inputComponent.controlName][this.index][
  //     this.controlName
  //   ] = [];
  //   this.updateMemo();
  //   this.totalFile = 0;
  // }

  hasSomeFileId(): boolean {
    if (!this.files) {
      return false;
    }
    return this.files?.some((f) => !!f.id);
  }

  addFile(file: File): void {
    this.files = [
      ...this.memoData[this.inputComponent.controlName],
      file,
    ];
    this.memoData[this.inputComponent.controlName] = this.files;
    this.updateMemo();
  }

  updateMemo(): void {
    this.store.dispatch(
      new SaveItemMemo(
        this.memoData[this.inputComponent.controlName],
        this.inputComponent.controlName,
      ),
    );
  }
}

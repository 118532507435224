import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/http/api.service';
import { Vendor, VendorDetail } from '../model/vendor.model';
import { API_URL } from '../../../shared/service/api.constant';

@Injectable({
  providedIn: 'root',
})
export class VendorService {
  constructor(private http: ApiService) {}

  createVendor(vendor) {
    return this.http.post(API_URL.vendors, vendor);
  }

  updateVendor(id: number, vendor) {
    return this.http.patch(API_URL.vendors + id + '/', vendor);
  }

  deleteVendor(id: number) {
    return this.http.delete<Vendor>(API_URL.vendors + id + '/');
  }

  getVendor(id) {
    return this.http.get<VendorDetail>(API_URL.vendors + id + '/');
  }

  getVendorList(params?): Observable<unknown> {
    return this.http.get(API_URL.vendors, params);
  }
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-shared-modal',
  templateUrl: './shared-modal.component.html',
  styleUrls: ['./shared-modal.component.scss'],
})
export class SharedModalComponent {
  @ViewChild('content', { static: true })
  content: ElementRef;
  themeList: ThemeList;
  submit = false;
  loading = false;

  @Input() adjustButton = false;
  @Output() next = new EventEmitter();
  @Output() cancel = new EventEmitter();
  constructor(
    public modalService: NgbModal,
    private themeService: ThemeService,
  ) {
    this.themeService.data.subscribe((theme) => {
      this.themeList = theme;
    });
  }

  open(config?): void {
    const option = config
      ? config
      : {
          size: 'md',
          backdrop: 'static',
          centered: true,
          keyboard: false,
        };
    this.modalService.open(this.content, option);
  }

  confirm(): void {
    this.next.emit();
  }

  closeModal(emit = true): void {
    if (emit) {
      this.cancel.emit();
    }
    this.modalService.dismissAll();
  }
}
